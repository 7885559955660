<template>
  <div style="height: 900px">
    <el-table
      stripe
      style="width: 90%; margin: 40px"
      height="850"
      :data="articles"
    >
      <el-table-column
        v-for="(title, index) in tableTitleList"
        :prop="title.prop"
        :label="title.name"
        :key="title.prop"
        :width="index == 1 ? 110 : 100"
        :index="title.index"
      >
      </el-table-column>
      <el-table-column prop="status" label="Status" width="120">
        <template #default="scope">
          <span v-if="scope.row.approvedStatus === 'under_assessment'">Article Pending</span>
          <span v-else-if="scope.row.approvedStatus === 'question_uploaded'">Question Pending</span>
          <span v-else>Unknown Status</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Operation" width="140">
        <template #default="scope">
          <div style="display: flex">
            <el-button
              v-if="route.query.admin > 1"
              type="danger"
              link
              size="small"
              @click="handleDelete(scope.row)"
            >Delete</el-button>
            <el-button
              v-if="scope.row.approvedStatus === 'under_assessment'"
              type="primary"
              link
              size="small"
              @click="handleArticleClick(scope.row)"
            >Moderate</el-button>
            <el-button
              v-if="scope.row.approvedStatus === 'question_uploaded'"
              type="primary"
              link
              size="small"
              @click="handleQuestionClick(scope.row)"
            >Moderate</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="articleManage" title="Manage Article" width="720">
      <el-card class="box-card" style="width: 670px;">
        <template #header>
          <div class="card-header">
            <span>Article: {{ article.name }}</span>
          </div>
        </template>
        <div>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="Create Time">
              {{ article.createTime }}
            </el-descriptions-item>
            <el-descriptions-item label="Topic">
              {{ article.topic }}
            </el-descriptions-item>
            <el-descriptions-item label="Category">
              {{ article.category }}
            </el-descriptions-item>
            <el-descriptions-item label="Link">
              <a :href="article.link" target="_blank">
                {{ article.link }}
              </a>
            </el-descriptions-item>
            <el-descriptions-item label="Source">
              {{ article.source }}
            </el-descriptions-item>
            <el-descriptions-item label="Reading Time">
              {{ article.readingTimes }}
            </el-descriptions-item>
            <el-descriptions-item label="User">
              {{ article.uname }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <br />
        <div>
          <el-radio-group v-model="radio1">
            <el-radio label="approved" border>Approve</el-radio>
            <el-radio label="disapproved" border>Disapprove</el-radio>
            <el-radio label="await_modification" border>Modify</el-radio>
          </el-radio-group>
        </div>
      </el-card>

      <textarea
        v-if="showComment"
        v-model="feedback"
        class="textarea"
        name="Comments"
        placeholder="e.g. what do you think about this article and this question"
        style="width: 635px"
      ></textarea>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="articleManage = false">Cancel</el-button>
          <el-button type="primary" @click="confirmArticle"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="questionManage" title="Manage Questions" width="720">
      <el-card class="box-card" style="width: 670px">
        <template #header>
          <div class="card-header">
            <span>Questions:</span>
          </div>
        </template>
        <div v-for="(question, index) in questions" :key="index">
          <el-descriptions :column="column">
            <el-descriptions-item label="Question">
              {{ question.question }}
            </el-descriptions-item>
            <el-descriptions-item label="Choice1">
              {{ question.choice1 }}
            </el-descriptions-item>
            <el-descriptions-item label="Choice2">
              {{ question.choice2 }}
            </el-descriptions-item>
            <el-descriptions-item label="Choice3">
              {{ question.choice3 }}
            </el-descriptions-item>
            <el-descriptions-item label="Choice4">
              {{ question.choice4 }}
            </el-descriptions-item>
            <el-descriptions-item label="Correct Choice">
              {{ getAns(question.correctChoice) }}
            </el-descriptions-item>
          </el-descriptions>
          <br />
          <div>
            <el-radio-group v-model="radio2[index]">
              <el-radio label="1" border :disabled="disable">Approve</el-radio>
              <el-radio label="-1" border>Disapprove</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-card>

      <textarea
        v-if="showComment"
        v-model="feedback"
        class="textarea"
        name="Comments"
        placeholder="e.g. what do you think about this article and this question"
        style="width: 635px"
      ></textarea>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="questionManage = false">Cancel</el-button>
          <el-button type="primary" @click="confirmQuestions"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
/* eslint-disable */
import { ref, watch } from "vue";
import { post } from "../../api/index";
import { formatDateTime } from "@/utils/dateFormatter";
import { getAns } from "@/utils/codeToString";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";

export default {
  name: "Pending",
  setup() {
    const column = ref(2);
    const disable = ref(false);
    const articleManage = ref(false);
    const questionManage = ref(false);
    const radio1 = ref("approved");
    const radio2 = ref(["1"]);
    const articles = ref([]);
    const article = ref("");
    const questions = ref([]);
    const route = useRoute();
    const feedback = ref("");
    const showComment = ref(false);
    const articleStatus = ref("under_assessment"); // Default to 'under_assessment'
    const tableTitleList = [
      { prop: "name", name: "Name" },
      { prop: "createTime", name: "CreateTime" },
      { prop: "topic", name: "Topic" },
      { prop: "category", name: "Category" },
      { prop: "overview", name: "Overview" },
      { prop: "uname", name: "User" },
    ];
    const uid = ref(-1);
    const uname = ref("");

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then((res) => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function handleDelete(row) {
      ElMessageBox.confirm(
        "Are you sure to delete this article record?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          post("/admin/delete-article", { articleId: row.id,moderator:uid.value }).then((res) => {
            if (res.res == 1) {
              ElMessage({
                type: "success",
                message: "Delete completed",
              });
              loadArticles();
            } else {
              ElMessage({
                type: "error",
                message: "Error, delete canceled",
              });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    }

    async function confirmArticle() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let articleEndpoint;
        const requestData = {
          articleId: article.value.id,
          moderator: Number(route.query.uid),
        };

        if (radio1.value === "approved") {
          articleEndpoint = "/admin/moderate-approve-article";
        } else {
          articleEndpoint = radio1.value === "disapproved" ? "/admin/moderate-disapprove-article" : "/admin/moderate-await-modification";
          requestData.comment = feedback.value;
        }

        await post(articleEndpoint, requestData);

        ElMessage.success("Moderation completed successfully.");
        setTimeout(() => {
          articleManage.value = false;
          loadArticles();
        }, 1000);
      } catch (error) {
        ElMessage.error("An error occurred. Please try again.");
      } finally {
        loading.close();
      }
    }

    async function confirmQuestions() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const questionModerationPromises = [];
        for (let i in radio2.value) {
          questionModerationPromises.push(
            post("/admin/moderating-question", {
              questionId: questions.value[i].questionId,
              status: radio2.value[i] === "1" ? 1 : 0,
              moderator: Number(route.query.uid),
            })
          );

          // Call /reject/add if the question is disapproved
          if (radio2.value[i] === "-1") {
            console.log(`Calling /reject/add for question ${questions.value[i].questionId}`);
            await post("/reject/add", {
              articleId: article.value.id,
              feedback: feedback.value,
              adminId: Number(route.query.uid),
              userId: article.value.uid,
            });
          }
        }
        await Promise.all(questionModerationPromises);

        // Send check all questions request after all moderating-question requests are done
        await post("/admin/check-all-question-by-article", { articleId: article.value.id });

        ElMessage.success("Question moderation completed successfully.");
        setTimeout(() => {
          questionManage.value = false;
          loadArticles();
        }, 1000);
      } catch (error) {
        ElMessage.error("An error occurred. Please try again.");
      } finally {
        loading.close();
      }
    }

    function handleArticleClick(row) {
      article.value = row;
      sessionStorage.setItem("newId", row.id.toString());

      post("/admin/check-review-article", { articleId: row.id }).then((res) => {
        articleStatus.value = res.approved_status;

        if (articleStatus.value === 'under_assessment') {
          articleManage.value = true;
        } else {
          ElMessage({
            type: "error",
            message: "Article is not under assessment.",
          });
        }
      }).catch(error => {
        ElMessage({
          type: "error",
          message: "An error occurred while checking the review status. Please try again.",
        });
      });
    }

    function handleQuestionClick(row) {
      article.value = row;
      sessionStorage.setItem("newId", row.id.toString());

      post("/question/get-question", { articleId: row.id }).then((res) => {
        if (res.questions && res.questions.length > 0) {
          questions.value = res.questions;
          radio2.value = new Array(res.questions.length).fill("1");
          questionManage.value = true;
        } else {
          ElMessage({
            type: "error",
            message: "No questions found for this article.",
          });
        }
      }).catch(error => {
        ElMessage({
          type: "error",
          message: "An error occurred while fetching questions. Please try again.",
        });
      });
    }

    function show_comment() {
      let flag = false;
      if (radio1.value === "disapproved" || radio1.value === "await_modification") {
        flag = true;
      }
      for (let i in radio2.value) {
        if (radio2.value[i] === "-1") {
          flag = true;
        }
      }
      console.log(`show_comment result: ${flag}`);
      return flag;
    }

    watch(
      () => radio1.value,
      () => {
        showComment.value = show_comment();
        if (radio1.value === "disapproved") {
          disable.value = true;
          for (let i in radio2.value) {
            radio2.value[i] = "-1";
          }
        } else {
          disable.value = false;
        }
      }
    );

    watch(
      () => radio2.value,
      () => {
        showComment.value = show_comment();
      },
      {
        deep: true,
      }
    );

    function loadArticles() {
      post("/admin/pending-article").then((res) => {
        articles.value = res.pending;
        const userNames = res.userNames;
        articles.value.forEach((article, index) => {
          article.uname = userNames[index];
          let old = new Date(article.createTime);
          article.createTime = formatDateTime(old, "yyyy-MM-dd HH:mm:ss");
        });
      });
    }

    loadArticles();

    return {
      column,
      articles,
      articleManage,
      questionManage,
      tableTitleList,
      radio1,
      radio2,
      handleArticleClick,
      handleQuestionClick,
      article,
      disable,
      questions,
      getAns,
      confirmArticle,
      confirmQuestions,
      showComment,
      feedback,
      handleDelete,
      route,
      articleStatus,
    };
  },
};
</script>




<style>
.textarea {
  width: 100%;
  font-size: 15px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 100px;
  margin-top: 10px;
}
</style>
